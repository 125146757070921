<template>
  <base-drop-down-menu-button
    ref="inboxFilterMenu"
    :close-on-content-click="false"
  >
    <template v-slot:iconName>
      {{ iconFilter }}
    </template>
    <template v-slot:commands>
      <!-- Sort by Status -->
      <v-card-title>
        <span :class="titleTextClass">{{ title }}</span></v-card-title
      >
      <v-divider></v-divider>
      <v-card-text>
        <v-checkbox
          dense
          hide-details
          v-model="selectAllOptions"
          :label="selectAllLabel"
          @change="toggleSelectAll()"
        ></v-checkbox>
        <v-checkbox
          class="ml-6 mb-0"
          dense
          hide-details
          v-for="option in options"
          :key="option"
          :value="option"
          v-model="selectedOptions"
          :label="option"
        ></v-checkbox>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-button
          :disabled="!selectedOptions.length"
          @click="onFilterApplied()"
          >Done</base-button
        >
      </v-card-actions>
    </template>
  </base-drop-down-menu-button>
</template>

<script>
import { iconFilter } from "@/design/icon/iconConst";
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";
import { eventNames } from "@/model/common/events/eventConst";
import { getLocalStorageItem } from "@/utils/localStorageUtility";
import {
  taskLocalStorageKeys,
  taskPriorities
} from "@/model/workflow/task/taskModel";

export default {
  name: "InboxFilterMenu",
  components: {
    BaseDropDownMenuButton: () =>
      import("@/components/shared/base/BaseDropDownMenuButton"),
    BaseButton: () => import("@/components/shared/base/BaseButton")
  },
  data() {
    return {
      iconFilter: iconFilter,
      titleTextClass: new Text(
        new Color(colorMD.indigo, variantType.lighten, variantNumber.n1),
        displayTypes.title,
        fontEmphasis.bold
      ).getClassText(),
      selectedOptions: this.taskPriorityNames(),
      selectAllOptions: true,
      options: this.taskPriorityNames()
    };
  },

  computed: {
    title() {
      return "Filter By Priority";
    },
    selectAllLabel() {
      return "All";
    }
  },

  methods: {
    /**
     * Toggle When select All Projects is Clicked
     */
    toggleSelectAll() {
      if (this.selectAllOptions) {
        this.selectedOptions = [...this.options];
      } else {
        this.selectedOptions = [];
      }
    },

    /**
     * Action on Filter Applied
     */
    onFilterApplied() {
      // Close the menu
      this.$refs.inboxFilterMenu?.closeMenu();
    },

    /**
     * get task Priority Names
     * @return {string[]} string array of predefined task Priority Names
     */
    taskPriorityNames() {
      return taskPriorities.map(el => el.text);
    }
  },

  watch: {
    selectedOptions() {
      this.selectAllOptions =
        this.selectedOptions.length === this.taskPriorityNames().length;
      this.$emit(eventNames.optionChanged, this.selectedOptions);
    }
  },

  mounted() {
    this.selectedOptions =
      JSON.parse(getLocalStorageItem(taskLocalStorageKeys.taskFilterOption)) ??
      this.taskPriorityNames();
  }
};
</script>

<style scoped></style>
